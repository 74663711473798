.datepickerWrapper :global .react-date-picker .react-date-picker__inputGroup {
  padding: 14px 12px;
  font-size: 14px;
}
.datepickerWrapper :global .react-date-picker .react-date-picker__wrapper {
  border-radius: 4px;
  border-color: rgb(203, 203, 203);
  border-width: 1px;
  box-sizing: border-box;
  border-style: solid;
}
.datepickerWrapper
  :global
  .react-date-picker
  .react-date-picker__wrapper:hover {
  border-color: rgb(10, 9, 9);
  cursor: pointer;
}
